<template>
  <div class="">
    <div class="Ptit">
      <strong class="tit">充值{{ state }}</strong>
    </div>
    <div>
      <div class="formTopJG">
        <div class="chongzhi" v-if="zt === 1">
          <div>
            <h4>请输入充值积分数</h4>
            <el-input
              class="jzdq"
              style="text-align:center;"
              placeholder="充值积分"
              maxlength="5"
              v-model="jine"
            >
              <template #prefix>
                <span class="disInB Ml8 Mr8">￥</span>
              </template>
              <template #suffix>
                <span class="disInB Ml8 Mr8">元</span>
              </template>
            </el-input>
          </div>
          <div class="duihuan">
            <!-- 积分兑换规则： 1元 = 1积分 -->
            <!-- ，单次充值金额 1 至 999 元 -->
          </div>
          <div class="pay" style="display:none">
            <div :class="pay === 'weixin' ? 'act' : ''" @click="pay = 'weixin'">
              <img class="xz" src="@/assets/img/pay-xz.png" />
              <img alt="微信" src="@/assets/img/pay-weixin.png" />
            </div>
            <div
              :class="pay === 'zhifubao' ? 'act' : ''"
              @click="pay = 'zhifubao'"
            >
              <img class="xz" src="@/assets/img/pay-xz.png" />
              <img alt="支付宝" src="@/assets/img/pay-zhifubao.png" />
            </div>
          </div>
          <div class="btn Mt24">
            <el-button type="success" @click="duihuanFun">确定</el-button>
          </div>
        </div>
        <div v-else>
          <el-result
            icon="success"
            title="提交成功"
            sub-title="请耐心等待管理员审核，通过后积分会到帐"
          >
            <template #extra>
              <el-button
                type="primary"
                style="min-width: 200px;"
                @click="goPage('/index')"
              >
                确定
              </el-button>
            </template>
          </el-result>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import { getUsPayChongzhi } from '@/api/user'
import { sign, getItem, setItem } from '@/utils/storage'
// import { ElMessageBox } from 'element-plus'
// import tools from '@/utils/tools'
import { useRouter } from 'vue-router'
const router = useRouter()

const pay = ref('weixin')
const jine = ref()
const zt = ref(1)

const duihuanFun = async () => {
  var rw = await getUsPayChongzhi(sign({ pay: pay.value, num: jine.value }))
  if (rw.zt === 'ok') {
    zt.value = 2
    // var ius = getItem('userinfo')
    // ius.integral = parseInt(ius.integral) + parseInt(rw.jf)
    // setItem('userinfo', ius)
    // ElMessageBox.alert('提交成功，请耐心等待管理员审核~', '提交成功', {
    //   confirmButtonText: 'OK',
    //   callback: action => {
    //     router.push('/usindex')
    //   }
    // })
  } else {
    console.log(getItem, setItem)
  }
  console.log(rw)
}
const goPage = ss => {
  router.push(ss)
}
</script>
<style scoped>
/deep/ .el-input__inner {
  text-align: center;
}
</style>
<style lang="scss" scoped>
.chongzhi {
  text-align: center;
  margin: 0 auto;
  max-width: 400px;
  h4 {
    margin-bottom: 16px;
    font-size: 16px;
  }

  .duihuan {
    margin-top: 14px;
    color: #d00;
  }
  .pay {
    display: flex;
    margin: 20px 0;
    > div {
      position: relative;
      flex: 1;
      cursor: pointer;
      text-align: center;
      padding: 5px 0;
      border: solid 1px #ddd;
      display: flex;
      align-items: center;
      justify-content: center;
      > img {
        width: 100px;
      }
      > img.xz {
        position: absolute;
        z-index: 3;
        right: 0;
        bottom: 0;
        width: 22px;
        height: 25px;
        display: none;
      }
    }
    > div:last-child {
      margin-left: 20px;
    }
    > div:hover {
      border-color: rgb(99, 99, 99);
    }
    > div.act {
      border-color: #d00;
      > img.xz {
        display: inline-block;
      }
    }
  }
  .btn {
    .el-button {
      width: 100%;
      padding: 18px 0;
    }
  }
}
</style>
